export const adminLteConf = {
    sidebarLeftMenu: [
        {
            label: 'Menu de Navegacion',
            separator: true
        },
        {
            label: ' Inicio', route: '/',
            iconClasses: 'fa fa-home ', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ]
        },
        { 
            label: ' Usuarios', 
            route: '/usuarios', 
            iconClasses: 'fa fa-user-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Usuarios App', 
            route: '/usuariosA', 
            iconClasses: 'fa fa-user-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Carpetas de investigación', 
            route: '/carpetas', 
            iconClasses: 'fa fa-folder-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Indicios', 
            route: '/indicios', 
            iconClasses: 'fa fa-address-book-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Indicios', 
            route: '/peritos', 
            iconClasses: 'fa fa-address-book-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Equipo', 
            route: '/patio', 
            iconClasses: 'fa fa fa-truck', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Ordenes', 
            route: '/ordenes', 
            iconClasses: 'fa fa-address-book-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Taller', 
            route: '/taller', 
            iconClasses: 'fa fa-car', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Socio Comercial', 
            route: '/clientes', 
            iconClasses: 'fa fa-users', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Recursos humanos', 
            route: '/empleados', 
            iconClasses: 'fa fa-id-badge', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Control de Personal', 
            route: '/entradas', 
            iconClasses: 'fa fa-user-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Control de Acceso', 
            route: '/recepciones', 
            iconClasses: 'fa fa-address-card-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Formatos', 
            route: '/formatos', 
            iconClasses: 'fa fa-file-text-o ', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Salida Equipo', 
            route: '/salidaequipo', 
            iconClasses: 'fa fa-file-text-o ', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        // { 
        //     label: ' Rutas', 
        //     route: '/rutas', 
        //     iconClasses: 'fa fa-map-marker', 
        //     pullRights: [
        //         { 
        //             classes: 'label pull-right bg-green' 
        //         }
        //     ] 
        // },
    ]
};